import "../../../styles/components/pages/homepage/AboutUs.scss";

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="about-us-text">
                <p className="pre-small black">
                    Компанія Релікт АРТЕ з 2004 року спеціалізується на
                    виробництві та оптово-роздрібній продажі міжкімнатних дверей
                    в Україні та на зарубіжжі. На даний час двері торгової марки
                    "Релікт АРТЕ" - це високоякісний, сучасний продукт, з
                    достатнім набором опцій та з доступною ціною. У 2015 році на
                    заводі проведено технічне переоснащення.
                </p>
                <p className="pre-small black">
                    Щомісяця завод виготовляє від 5000 до 10000 одиниць виробів.
                    На всіх етапах роботи підприємства діє система контролю
                    якості, від вибору постачальників до доставки готового
                    товару споживачеві.
                </p>
            </div>
            <div className="about-us-image"></div>
        </div>
    );
};

export default AboutUs;
